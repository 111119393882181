<template>
  <el-container class="home-container visual-base" style="height: 100vh">
    <div class="logout" @click="logout">
      <img src="../assets/images/logout.png" alt="" />
    </div>
    <div class="visual-starry-bg"></div>
    <el-header class="header">
      <!-- <img src="../assets/images/cssb.png" /> -->
      <!-- <img src="../assets/images/iotech.png" /> -->
    </el-header>
    <el-main>
      <div class="companyName">
        <!-- <span>长沙世邦</span>     -->
        <span>城市大厦</span>    
      </div>
      <div class="companyName1">消防物联网集中监控平台</div>
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="5" class="rotation" v-show="isShow.show">
          <section class="inner" @click="myDevice('主页', 'Show')">
            <div class="iconfont icon-zhuye"></div>
            <div class="grid-content">主页</div>
          </section>
        </el-col>
        <el-col :span="5" class="rotation" v-show="isShow.deviceInfoManagement">
          <section
            class="inner"
            @click="myDevice('我的设备', 'DeviceInfoManagement')"
          >
            <div class="iconfont icon-shebeiweihuguanli"></div>
            <div class="grid-content">我的设备</div>
          </section>
        </el-col>
        <el-col :span="5" class="rotation" v-show="isShow.crtShow">
          <section class="inner" @click="myDevice('CRT管理', 'CRTShow')">
            <div class="iconfont icon-shebeixinxi"></div>
            <div class="grid-content">CRT管理</div>
          </section>
        </el-col>
        <el-col :span="5" class="rotation" v-show="isShow.history">
          <section class="inner" @click="myDevice('历史记录', 'History')">
            <div class="iconfont icon-lishijilu"></div>
            <div class="grid-content">历史记录</div>
          </section>
        </el-col>
        <!-- 一键报警 -->
        <!-- <el-col :span="5" class="rotation" v-show="isShow.aKeyAlarm">
          <section class="inner" @click="myDevice('一键报警', 'AKeyAlarm')">
            <div class="iconfont icon-caidanguanli"></div>
            <div class="grid-content">一键报警</div>
          </section>
        </el-col> -->
        
        <el-col :span="5" class="rotation" v-show="isShow.mapBuiding">
          <section class="inner" @click="myDevice('数据可视化', 'MapBuiding')">
            <div class="iconfont icon-keshihua"></div>
            <div class="grid-content">数据可视化</div>
          </section>
        </el-col>
        <el-col :span="5" class="rotation" v-show="isShow.subscribeManagement">
          <section
            class="inner"
            @click="myDevice('订阅管理', 'SubscribeManagement')"
          >
            <div class="iconfont icon-dingyue"></div>
            <div class="grid-content">订阅管理</div>
          </section>
        </el-col>
        <el-col
          :span="5"
          class="rotation"
          v-show="isShow.permissionSysManagement"
        >
          <section
            class="inner"
            @click="myDevice('权限管理', 'PermissionSysManagement')"
          >
            <div class="iconfont icon-keshihua"></div>
            <div class="grid-content">管理系统</div>
          </section>
        </el-col>


        <!-- <el-col
          :span="5"
          class="rotation"
          v-show="isShow.OperationLog"
        >
          <section
            class="inner"
            @click="myDevice('操作日志', 'OperationLog')"
          >
            <div class="iconfont icon-lishijilu"></div>
            <div class="grid-content">操作日志</div>
          </section>
        </el-col> -->

      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import user from "@/api/manageApi/User";
import dataStorage from "@/../utils/dataStorage.js";

export default {
  data() {
    return {
      userMenu: [],
      isShow: {
        show: false, // 主页
        deviceInfoManagement: false, // 我的设备
        crtShow: false, // CRT管理
        history: false, // 历史记录
        aKeyAlarm: false, //一键报警
        mapBuiding: false, // 数据可视化
        subscribeManagement: false, // 订阅管理
        permissionSysManagement: false, // 管理系统
        OperationLog: false,  // 操作日志
      },
    };
  },
  created() {
    this.getLoginUserInfo();
  },
  methods: {
    logout() {
      user.logout().then((res) => {});
      this.$router.replace("/");

    },

    myDevice(msg, routeText) {
      this.$router.push({
        name: routeText,
        params: {
          msg: msg,
        },
      });
    },

    getLoginUserInfo() {
      user.loginUserInfo().then((res) => {
        dataStorage.setBaseData("socketUserName", res.data.data.userName);
        if (res.data.data.userName == "admin") {
          for (let i in this.isShow) {
            this.isShow[i] = true;
          }
        } else {
          for (let i in res.data.data.menu.list) {
            this.isShow[res.data.data.menu.list[i]] = true;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.companyName {
  width: 100%;
  margin-top: 5%;
  // margin: 60px auto 0;
  // font: bold 5vw/100% "", Times, serif;
  font-size: 80px;
  font-family: "楷体";
  font-weight: 700;
  color: #7ae5f9;
  text-align: center;
  letter-spacing: 0;
  text-shadow: 5px 5px 3px #000;
}
.companyName1 {
  width: 100%;
  margin: 40px auto 0;
  // font: bold 5vw/100% "", Times, serif;
  font-size: 80px;
  font-family: "楷体";
  font-weight: 700;
  color: #6edcf8;
  text-align: center;
  letter-spacing: 0;
  text-shadow: 5px 5px 3px #000;
}

.el-main {
  z-index: 500 !important;
}

.el-row {
  height: 200px;
  color: #122e46;
  margin-top: 5%;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

/deep/ .el-col {
  font: bold 20px/100% "Times New Roman", Times, serif;
  transform: scale(0.8, 0.8) !important;
  background: url(../assets/images/welcom-bg.png) no-repeat center center;
  border-radius: 50%;

  div {
    text-align: center;
  }

  section {
    padding: 50px 0;
  }
}

.el-col-4 {
  width: 18.66667%;
}

.el-col:hover {
  cursor: pointer;
}

.iconfont {
  font-size: 46px !important;
  margin-bottom: 18px;
}

.row-bg {
  padding: 20px 0;
}

.rotation:hover {
  animation: rotation 6s linear;
  // 动画无限循环
  animation-iteration-count: infinite;
}

.inner:hover {
  animation: inner 6s linear;
  // 动画无限循环
  animation-iteration-count: infinite;
}

@keyframes inner {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-90deg);
  }

  50% {
    transform: rotate(-180deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.logout {
  position: absolute;
  right: 30px;
  top: 30px;
}
.logout img {
  width: 30px;
}
.logout img:hover {
  width: 40px;
  transition: 0.5s;
}
.header img {
  margin: 30px 0 0 0;
  width: 20%;
  mix-blend-mode: screen; // 滤色
  // filter: invert(100%);
}
</style>
